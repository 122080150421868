
import Vue from "vue";
import { mapState } from "vuex";
import { Project, View } from "@/code/project";
import designView from "../components/design-view.vue";

export default Vue.extend({
  components: { designView },
  name: "Design",

  data: () => ({
    pinned: true,
    panel: 0,

    exportData: "",

    project: new Project("", ""),

    editName: false,

    openItems: null as unknown as View[],
    current: null as unknown as View,

    themeDlg: false,

    colors: [
      {
        name: "Oranges",
        values: [
          "#ff5722",
          "#ff9800",
          "#2196f3",
          "#f44336",
          "#00bcd4",
          "#4caf50",
          "#78bc34",
          "#78bc34",
        ],
      },
      {
        name: "Cherry",
        values: [
          "#e91e63",
          "#03a9f4",
          "#ff9800",
          "#f44336",
          "#ffeb3b",
          "#00bcd4",
          "#74caf5",
          "#78bc34",
        ],
      },
      {
        name: "Blueberry",
        values: [
          "#3f51b5",
          "#03a9f4",
          "#8bc34a",
          "#f44336",
          "#ff9800",
          "#009688",
          "#74caf5",
          "#78bc34",
        ],
      },
      {
        name: "Seattle",
        values: [
          "#c2c2c1",
          "#f3f3f3",
          "#8bc34a",
          "#f44336",
          "#ff9800",
          "#009688",
          "#74caf5",
          "#78bc34",
        ],
      },
    ],
  }),

  mounted() {
    this.openItems = [];

    this.project = this.session.projects.find(
      (p: Project) => p.id == this.$route.params.projectId
    );
  },

  computed: {
    ...mapState(["session"]),

    isViewType() {
      return true; // this.current.constructor.name == "View";
    },
  },

  methods: {
    editTitle() {
      this.editName = true;
    },

    addView() {
      const view = this.project.addView();

      this.openItem(view);
    },

    deleteView(view: View) {
      this.closeItem(this.project.views.indexOf(view));
      this.project.views = this.project.views.filter((c) => c != view);
    },

    openItem(view: View) {
      let alreadyOpen = this.openItems.find((f: View) => f.id == view.id);
      if (alreadyOpen) {
        this.current = alreadyOpen;
      } else {
        this.openItems.push(view);
        this.current = view;
      }
    },

    closeItem(index: number) {
      this.openItems.splice(index, 1);
      if (this.openItems.length) {
        if (index < this.openItems.length || index == 0)
          this.current = this.openItems[index];
        else this.current = this.openItems[index - 1];
      } else {
        this.current = null as unknown as View;
      }
    },

    downloadProject() {
      this.project.download();
    },

    exportProject() {
      //  const url = `https://api.github.com/repos/bassamsaliba/vv/contents/test2.json`;
      //  Project.saveToGit(url, this.project);
      this.exportData = JSON.stringify(this.project, null, 2);
    },
  },
});
