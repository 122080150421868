import { Control } from "@/code/project";
import { Widget, WidgetSerial, WidgetType } from "@/code/widget"

export class Input extends Widget {
  constructor() {
    super("Input", "Input", "mdi-form-textbox");

    super.addProperty("label", "Input", WidgetType.Text);
    super.addProperty("alignment", 0, WidgetType.List, 0, 100, [
      "Left",
      "Right",
      "Center"
    ]);
  }

  serialize(control: Control): WidgetSerial {
    const template = `\t<v-text-field v-model="${control.propertyValue('model')}" label="${control.propertyValue('label')}"></v-text-field>\n`;
    return { template: template, methods: "", data: ""};
  }
} 