import { Control } from "@/code/project";
import { Widget, WidgetSerial, WidgetType } from "@/code/widget"

export class Boolean extends Widget {
  constructor() {
    super("Boolean", "boolean", "mdi-toggle-switch-outline");

    super.addProperty("label", "Label here", WidgetType.Text);
    super.addProperty("class", "", WidgetType.Text);
    super.addProperty("type", "switch", WidgetType.List, 0, 0, ['switch', 'checkbox']);
    super.addHandler("click");
    super.addHandler("dblclick");
  }

  serialize(control: Control): WidgetSerial {
    let template = '';
    
    if (control.propertyValue('type') == 'switch')
      template = `\t<v-switch ${control.serializeHandlerCallers()} class="${control.propertyValue('class')}">${control.propertyValue('label')}</v-switch>\n`;
    else
      template = `\t<v-checkbox ${control.serializeHandlerCallers()} class="${control.propertyValue('class')}">${control.propertyValue('label')}</v-checkbox>\n`;
    return { template: template, methods: "", data: ""};
  }
} 