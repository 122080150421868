import { Control } from "@/code/project";
import { Widget, WidgetSerial, WidgetType } from "@/code/widget"

export class Btn extends Widget {
  constructor() {
    super("Button", "btn", "mdi-gesture-tap-button");

    super.addProperty("elevation", "1", WidgetType.Slider, 0, 25);
    super.addProperty("label", "Label", WidgetType.Text);
    super.addProperty("class", "primary", WidgetType.Text);
    super.addProperty("dark", false, WidgetType.Switch);
    super.addHandler("click");
    super.addHandler("dblclick");
  }

  serialize(control: Control): WidgetSerial {
    const template = `\t<v-btn ${control.serializeHandlerCallers()} class="${control.propertyValue('class')}">${control.propertyValue('label')}</v-btn>\n`;
    return { template: template, methods: "", data: ""};
  }
} 