
import Vue from "vue";
import { View, Control } from "@/code/project";
import { Widget } from "@/code/widget";
import { mapState } from "vuex";
import Draggable from "vuedraggable";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import { Btn } from "@/components/widgets/btn";
import { Slider } from "@/components/widgets/slider";
import { Input } from "@/components/widgets/input";
import { Card } from "@/components/widgets/card";
import { Chart } from "@/components/widgets/chart";
import { Imgx } from "@/components/widgets/imgx";
import { Html } from "@/components/widgets/html";
import { Sample } from "@/components/widgets/sample";
import { Boolean } from "@/components/widgets/boolean";

export default Vue.extend({
  name: "Design",

  components: {
    Draggable,
    Splitpanes,
    Pane,
    Btn: () => import("@/components/widgets/btn.layout.vue"),
    Slider: () => import("@/components/widgets/slider.layout.vue"),
    Card: () => import("@/components/widgets/card.layout.vue"),
    Input: () => import("@/components/widgets/input.layout.vue"),
    Chart: () => import("@/components/widgets/chart.layout.vue"),
    Html: () => import("@/components/widgets/html.layout.vue"),
    Imgx: () => import("@/components/widgets/imgx.layout.vue"),
    Boolean: () => import("@/components/widgets/boolean.layout.vue"),

    Sample: () => import("@/components/widgets/sample.layout.vue"),
  },

  props: {
    page: View,
  },

  data: () => ({
    controlsDlg: false,
    horizontal: true,
    tab: 0,

    currentControl: null as unknown as Control,

    code: "",

    widgets: [] as Widget[],

    jsOptions: {
      // codemirror options
      tabSize: 2,
      mode: "javascript",
      lineNumbers: true,
      line: true,
    },

    classOptions: {
      // codemirror options
      tabSize: 2,
      mode: "htmlmixed",
      lineNumbers: true,
      line: true,
    },
  }),

  mounted() {
    document.addEventListener("keyup", (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == "x") {
        this.removeSelectedControls();
      }
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == "a") {
        this.selectAllControls();
      }
    });

    this.widgets.push(new Btn());
    this.widgets.push(new Slider());
    this.widgets.push(new Input());
    this.widgets.push(new Card());
    this.widgets.push(new Html());
    this.widgets.push(new Chart());
    this.widgets.push(new Boolean());
    this.widgets.push(new Imgx());

    this.widgets.push(new Sample());
  },

  computed: {
    ...mapState(["projects"]),
  },

  methods: {
    addControl(widget: Widget) {
      //
      this.page.addControl(widget);
    },

    deselectAllControls() {
      this.page.controls.forEach((c) => {
        c.selected = false;
      });
    },

    selectControl(c: Control, single: boolean) {
      console.log("single: " + single);
      if (single) {
        this.deselectAllControls();
      }

      this.currentControl = c;
      c.selected = true;
    },

    removeControl(c: Control) {
      this.page.removeControl(c);
      this.currentControl = this.page.controls.length
        ? this.page.controls[0]
        : (null as unknown as Control);
    },

    removeSelectedControls() {
      this.page.controls.forEach((c) => {
        if (c.selected) this.page.removeControl(c);
      });
    },

    selectAllControls() {
      this.page.controls.forEach((c) => {
        c.selected = true;
      });
    },

    updateRoute(view: View) {
      view.route = '/' + view.name.toLowerCase().replaceAll(' ', '-') 
    }
  },
});
