import { Control } from "@/code/project";
import { Widget, WidgetSerial, WidgetType } from "@/code/widget"

export class Slider extends Widget {
  constructor() {
    super("Slider", "slider", "mdi-soccer-field");

    super.addProperty("min", 0, WidgetType.Slider, 0, 100);
    super.addProperty("max", 0, WidgetType.Slider, 0, 100);
    super.addProperty("label", "Slider", WidgetType.Text);
    super.addProperty("alignment", 0, WidgetType.List, 0, 100, [
      "Left",
      "Right",
      "Center"
    ]);
  }

  serialize(control: Control): WidgetSerial {
    const template = `\t<v-slider v-model="${control.propertyValue('model')}" label="${control.propertyValue('label')}"></v-slider>\n`;
    return { template: template, methods: "", data: ""};
  }
} 