import { Control } from "@/code/project";
import { Widget, WidgetSerial, WidgetType } from "@/code/widget"

export class Card extends Widget {
  constructor() {
    super("card", "card", "mdi-card");

    super.addProperty("elevation", "1", WidgetType.Slider, 0, 25);
    super.addProperty("title", "Title", WidgetType.Text);
    super.addProperty("text", "text", WidgetType.TextArea);
    super.addProperty("class", "primary", WidgetType.Text);
    super.addProperty("dark", true, WidgetType.Switch);
    super.addHandler("click");
    super.addHandler("dblclick");
  }

  serialize(control: Control): WidgetSerial {
    const template = `\t<v-card ${control.serializeHandlerCallers()} class="${control.propertyValue('class')}" :dark="${control.propertyValue('dark')}"><v-card-title>${control.propertyValue('title')}</v-card-title><v-card-body>${control.propertyValue('text')}</v-card-body></v-card>\n`;
    return { template: template, methods: "", data: ""};
  }
} 