import { render, staticRenderFns } from "./design.vue?vue&type=template&id=fd697226&scoped=true&"
import script from "./design.vue?vue&type=script&lang=ts&"
export * from "./design.vue?vue&type=script&lang=ts&"
import style0 from "./design.vue?vue&type=style&index=0&id=fd697226&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd697226",
  null
  
)

export default component.exports