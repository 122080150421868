import { Control } from "@/code/project";
import { Widget, WidgetSerial, WidgetType } from "@/code/widget"

export class Chart extends Widget {
  constructor() {
    super("chart", "chart", "mdi-chart-areaspline");

    super.addProperty("elevation", "1", WidgetType.Slider, 0, 25);
    super.addProperty("data", "[1, 2, 3]", WidgetType.Text);
    super.addProperty("class", "primary", WidgetType.Text);
    super.addProperty("color", "#fff", WidgetType.Text);
    super.addProperty("line-width", "5", WidgetType.Slider, 0, 10);
    super.addProperty("type", true, WidgetType.List, 0, 0, ['trend', 'bars']);
    super.addProperty("smooth", true, WidgetType.Switch);
    super.addProperty("fill", true, WidgetType.Switch);
    super.addProperty("show-labels", true, WidgetType.Switch);
    super.addProperty("dark", true, WidgetType.Switch);
    super.addHandler("click");
  }

  serialize(control: Control): WidgetSerial {
    const template = `\t<v-sparkline ${control.serializeHandlerCallers()} class="${control.propertyValue('class')}" :dark="${control.propertyValue('dark')}" color="${control.propertyValue('color')}" type="${control.propertyValue('type')}" :value="${control.propertyValue('data')}"></v-sparkline>\n`;
    return { template: template, methods: "", data: ""};
  }
} 