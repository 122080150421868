import { Control } from "@/code/project";
import { Widget, WidgetSerial, WidgetType } from "@/code/widget"

export class Sample extends Widget {
  constructor() {
    super("Sample", "sample", "mdi-gamepad");

    super.addProperty("text", "", WidgetType.Text);
    super.addProperty("class", "primary", WidgetType.Text);
    super.addHandler("click");
    super.addHandler("dblclick");
  }

  serialize(control: Control): WidgetSerial {
    const template = `\t<div ${control.serializeHandlerCallers()} class="${control.propertyValue('class')}">${control.propertyValue('text')}</div>\n`;
    return { template: template, methods: "", data: ""};
  }
} 